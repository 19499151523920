import { useEffect, useLayoutEffect, useRef, useState } from "react"
import './styles/Chat_Page.css'

import ErrorPage from "./ErrorPage"

let chat_arr = [{
                    type: 'kebe',
                    message: 'ሰላም!',
                    time: new Date().toLocaleTimeString()
                }]

const ChatPage = ({URL}) => {
    
    const [chatTxt, setChatTxt] = useState('')
    
    const [chat_array, setChatArray] = useState(chat_arr)
    
    const [apiController, setAPIController] = useState(false)

    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(false)

    const timer_controller = useRef();

    const chat_array_updater = (new_value) => {
        setChatArray((arrVal)=>{
           return arrVal.concat(new_value)
        })        
        return;
    }

    const chatSubmitted = ()=> {

        setAPIController(true);

        setLoading(true)
        const user_obj = {
                    type: 'user',
                    message: chatTxt, 
                    time: new Date().toLocaleTimeString()           
                }

        chat_array_updater(user_obj)
        
        setChatTxt('')

      }

      const timeTrackFunction = () => {
                 
        return new Promise(
            (resolve, reject)=> {
                     return setTimeout(()=>{
                            fetch(URL+'/awaiting')
                                .then((res)=>res.json())
                                .then((res)=>{

                                    console.log(res);             
                                    
                                    resolve(false);
                                   })

                                .catch(()=>{
                                    reject();
                                })
                                }, 2000)
                    }
                )
      }

    useEffect(()=>{

        if(apiController)
        {   
            timer_controller.current = false

            fetch(URL+'/user_submitted', {
                method: "POST",
                body: JSON.stringify(chat_array),
                headers: {
                    "Content-Type": 'application/json'
                }
            })
            .then((res)=>
            {
                return res.json()
            })
            .then(
                (res)=>{
                    setLoading(false)

                    timer_controller.current = true
                    
                    if(res.ok)
                    {
                        console.log(res.message)

                        const kebe_obj = {
                            type: 'kebe',
                            message: res.message, 
                            time: new Date().toLocaleTimeString()           
                        }
                        chat_array_updater(kebe_obj)
                        setAPIController(false)
                    }
                    else
                    {
                        setErr(true)
                    }
                }
            )
            .catch((err)=>{
                setErr(true)
                console.log(err)
            })
            
            async function timer() {
                console.log('Stream started.')
                    
                for(let i=0; i>=0; i++)
                {
                   
                    await timeTrackFunction();
                    

                    if(timer_controller.current)
                    {
                        console.log("Stream stopped.")
                        break;
                    }
                }
            }

            timer();
        
        }

    }, [chat_array])


    const chatDisplay = (arr) => {
        
        let chatDisplayReturnArray = []

        arr.forEach((val, index)=>{
            const cN = (val.type==='user') ? 'chatDisplayLeft' : 'chatDisplayRight'

            const iDTag = (val.type==='user')? 'እርስዎ' : 'ከቤ'
            
            let return_elt = <div className={cN}
                                    key={index}
                                    >
                                <h3>{`${val.message}`}</h3>
                                <code>{iDTag}</code>
                                <code>{val.time}</code>  
                            </div>
            chatDisplayReturnArray.push(return_elt)
        })
        
        return chatDisplayReturnArray;
    }

    return <div className="chat_page_main_container">
                {(err)?
                    <ErrorPage/>
                        :
                    <></>
                }
                <h1
                    className="chat_page_main_text"> {"#ከቤ_ይስራው"}</h1>
                
                {(chat_array)?
                    chatDisplay(chat_array)
                        :
                    <></>
                }

                {
                    (loading) ?
                        <p>... ከቤ እየመለሰ ነው። </p>
                            :
                        <></>
                }
                <div className="input_bar_container">
                    <input
                        className="input_bar"
                        placeholder="ትዕዛዝዎን ያስገቡ|ያናግሩ።"  
                        onChange={(e)=>{
                            setChatTxt(e.target.value)  
                        }}
                        value={chatTxt}
                        />

                    <button
                            className="input_button"
                            onClick={()=>{
                                chatSubmitted();
                            }}>
                            ያስገቡ
                        </button>
                    </div>
                </div>;
    }


export default ChatPage;