import './App.css';

import {useState} from 'react'
import LandingPage from './components/LandingPage';
import ChatPage from './components/Chat_Page'


const URL = ''

function App() {
  
  const [routerState, setRouterState] = useState(0)
  //routerState
    //0 - LandingPage
    //1 - ChatSpace
  

  return <div className='all_container'>
            {(routerState===0) ?
              <LandingPage
                  setRouterState={setRouterState}
                />
                    :
              <ChatPage 
                  URL = {URL}
                  />
            }
        </div>
          }    

export default App;
