import './styles/LandingPage.css'
import Signature from './Signature'

const LandingPage = ({setRouterState}) => {
        return  <div className='main_container'>
                    
                    <h1 className='intro_text'>{'ኬቤ ብዙ ነገሮችን በአማርኛ ማከናወን ይችላል፣ ከኋላው GPT 3.5-Turbo ስለሆነ። ለከቤ እድገት፣ ንግግሮ ይቀረጻል።'}</h1>
                    
                    <button 
                        className='try_it_button'
                        onClick={()=>{
                            setRouterState(1)
                        }}
                        >
                        ይሞከር
                    </button>

                    <Signature/>
                </div>

}

export default LandingPage;